import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import ChatWidget from './components/ChatWidget';
import AnalyticsReport from './components/AnalyticsReport';
import { motion, AnimatePresence } from 'framer-motion';
import { ChatBubbleOvalLeftEllipsisIcon } from '@heroicons/react/24/solid';

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([{ role: 'assistant', content: `Hi, I'm Alex! Welcome to the Greenfield Union School District website. I'm here to help with anything you need, and I can chat with you in multiple languages. You can talk to me by typing or using your voice, just click the microphone to start and stop. To see our conversation, click the back arrow in the chat. How can I assist you today? I'm always here to help!` }]);

  const toggleChatWidget = () => {
    setIsOpen(!isOpen);
  };

  const shakeAnimation = {
    animate: {
      rotate: [0, 10, -10, 10, -10, 0],
      transition: {
        duration: 0.5,
        repeat: Infinity,
        repeatType: 'loop',
        ease: 'easeInOut',
      },
    },
  };

  return (
    <Router>
      <div className="App relative">
        <nav className="p-4 bg-blue-500 text-white flex justify-end">
          <Link to="/" className="mr-4">Home</Link>
          <Link to="/analytics">Analytics Report</Link>
        </nav>

        <Routes>
          <Route
            path="/"
            element={
              <>
                <img
                  src="/web.jpeg" // Replace with your actual image URL
                  alt="Long website screenshot"
                  className="w-full"
                />
                <div className="absolute top-100 left-0 w-full h-full">
                  <motion.button
                    onClick={toggleChatWidget}
                    className="fixed bottom-8 right-6 bg-black text-white p-2 rounded-full shadow-lg focus:outline-none"
                    style={{ width: '70px', height: '70px' }}
                    {...shakeAnimation}
                  >
                    <img src="/head.png" alt="SFA Logo" className="h-full w-full rounded-full" />
                    {/* <ChatBubbleOvalLeftEllipsisIcon className="h-12 w-12" /> */}
                  </motion.button>
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        initial={{ opacity: 0, scale: 0.8 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.8 }}
                        className="fixed bottom-0 right-0 m-4 z-20"
                      >
                        <ChatWidget onClose={toggleChatWidget} messages={messages} setMessages={setMessages} />
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </>
            }
          />
          <Route path="/analytics" element={<AnalyticsReport />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
