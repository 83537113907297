import React, { useState, useRef } from 'react';
import { MicrophoneIcon, PaperAirplaneIcon, XMarkIcon, ArrowLeftIcon } from '@heroicons/react/24/solid';
import axios from 'axios';
import { motion } from 'framer-motion';

const ChatWidget = ({ onClose, messages, setMessages }) => {
  const [input, setInput] = useState('');
  const [statusMessage, setStatusMessage] = useState('Press the button to start talking');
  const [listening, setListening] = useState(false);
  const [speaking, setSpeaking] = useState(false);
  const [inVoiceMode, setInVoiceMode] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  const handleSend = async (text) => {
    if (input.trim() !== '' || text) {
      const newMessage = { role: 'user', content: input || text };
      const updatedMessages = [...messages, newMessage];
      setMessages(updatedMessages);
      setInput('');
      setStatusMessage('Sending...');

      try {
        const response = await axios.post('https://bwk7otvk27.execute-api.us-east-2.amazonaws.com/chat', {
          chatHistory: updatedMessages,
        });
        const { content, chatHistory } = response.data;

        if (inVoiceMode) {
          setStatusMessage('Synthesizing response...');
          const synthesizeResponse = await axios.post('https://bwk7otvk27.execute-api.us-east-2.amazonaws.com/synthesize', { text: content });
          const audioUrl = synthesizeResponse.data.audioUrl;
          const audio = new Audio(audioUrl);
          setStatusMessage('Playing response...');
          audio.play();
          setSpeaking(true);
          setMessages(chatHistory);
          audio.onended = () => {
            setSpeaking(false);
            setStatusMessage('Press the button to start talking');
          };
        } else {
          setMessages(chatHistory);
          setStatusMessage('Press the button to start talking');
        }
      } catch (error) {
        console.error('Error sending chat message:', error);
        setStatusMessage('Error occurred. Try again.');
      }
    }
  };

  const handleVoiceInput = () => {
    if (listening) {
      // Stop recording and process the audio
      mediaRecorderRef.current.stop();
      setListening(false);
    } else {
      // Start recording
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
          const mediaRecorder = new MediaRecorder(stream);
          mediaRecorderRef.current = mediaRecorder;
          audioChunksRef.current = [];

          mediaRecorder.ondataavailable = event => {
            audioChunksRef.current.push(event.data);
          };

          mediaRecorder.onstop = async () => {
            setStatusMessage('Processing...');
            const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
            const audioFile = new File([audioBlob], 'recording.wav', { type: 'audio/wav' });
            const formData = new FormData();
            formData.append('audio', audioFile);

            stream.getTracks().forEach(track => track.stop());

            try {
              const transcribeResponse = await axios.post('https://bwk7otvk27.execute-api.us-east-2.amazonaws.com/transcribe', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
              });

              const text = transcribeResponse.data.text;
              setInput(text);  // Set the transcribed text as input
              handleSend(text);  // Automatically send the transcribed text
            } catch (error) {
              console.error('Error processing audio:', error);
              setStatusMessage('Error occurred. Try again.');
            }
          };

          mediaRecorder.start();
          setListening(true);
          setStatusMessage('Listening...');
        })
        .catch(error => {
          console.error('Error accessing audio:', error);
          setStatusMessage('Error accessing microphone. Check permissions.');
        });
    }
  };

  const switchToVoiceMode = () => {
    setInVoiceMode(true);
  };

  const switchToChatMode = () => {
    setInVoiceMode(false);
  };

  if (inVoiceMode) {
    return (
      <div className="bg-white shadow-lg rounded-lg p-4 w-80 flex flex-col items-center justify-center">
        <button onClick={switchToChatMode} className="absolute top-2 left-2 text-gray-500 hover:text-gray-700 focus:outline-none">
          <ArrowLeftIcon className="h-6 w-6" />
        </button>
        <h2 className="text-lg font-semibold mb-4">Voice Interaction</h2>
        <motion.button
          onClick={handleVoiceInput}
          className="bg-blue-500 text-white p-6 rounded-full shadow-lg focus:outline-none"
          animate={{
            scale: listening ? 1.2 : 1,
            backgroundColor: listening ? '#ef4444' : '#3b82f6',
          }}
          transition={{ duration: 0.2 }}
        >
          <MicrophoneIcon className="h-8 w-8" />
        </motion.button>
        <p className="mt-4 text-gray-600">
          {statusMessage}
        </p>
      </div>
    );
  }

  return (
    <div className="bg-white shadow-lg rounded-lg p-4 w-80">
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-lg font-semibold">Chat</h2>
        <button onClick={onClose} className="text-gray-500 hover:text-gray-700 focus:outline-none">
          <XMarkIcon className="h-6 w-6" />
        </button>
      </div>
      <div className="h-[25rem] overflow-y-auto mb-4" style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`mb-2 p-2 rounded-lg ${
              msg.role === 'user' ? 'bg-blue-500 text-white self-end' : 'bg-gray-300'
            }`}
          >
            {msg.content}
          </div>
        ))}
      </div>
      <div className="flex items-center">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => { if (e.key === 'Enter') handleSend(); }}
          className="flex-grow border rounded-l-lg p-2 focus:outline-none"
          placeholder="Type a message..."
        />
        <motion.button
          onClick={switchToVoiceMode}
          className="p-2 bg-blue-500 text-white rounded-lg ml-2 focus:outline-none"
          animate={{
            scale: 1.2,
            backgroundColor: '#3b82f6',
          }}
          transition={{ duration: 0.2 }}
        >
          <MicrophoneIcon className="h-5 w-5" />
        </motion.button>
      </div>
    </div>
  );
};

export default ChatWidget;
