import React from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const AnalyticsReport = () => {
  // Example data for the charts
  const interactionData = {
    labels: ['Interactions', 'Active Users', 'Sessions'],
    datasets: [
      {
        label: 'User Interaction Metrics',
        data: [5200, 1150, 3450],
        backgroundColor: ['rgba(75, 192, 192, 0.6)', 'rgba(54, 162, 235, 0.6)', 'rgba(255, 206, 86, 0.6)'],
      },
    ],
  };

  const languageData = {
    labels: ['English', 'Spanish', 'Other'],
    datasets: [
      {
        label: 'Language Preferences',
        data: [65, 30, 5],
        backgroundColor: ['rgba(255, 99, 132, 0.6)', 'rgba(54, 162, 235, 0.6)', 'rgba(75, 192, 192, 0.6)'],
      },
    ],
  };

  const queryData = {
    labels: [
      'School Calendar and Events',
      'Enrollment Information',
      'Lunch Menu',
      'Homework Help',
      'Contact Information',
      'Bus Schedule',
      'Others',
    ],
    datasets: [
      {
        label: 'Popular Queries',
        data: [20, 15, 10, 8, 7, 5, 35],
        backgroundColor: [
          'rgba(255, 99, 132, 0.6)',
          'rgba(54, 162, 235, 0.6)',
          'rgba(255, 206, 86, 0.6)',
          'rgba(75, 192, 192, 0.6)',
          'rgba(153, 102, 255, 0.6)',
          'rgba(255, 159, 64, 0.6)',
          'rgba(201, 203, 207, 0.6)',
        ],
      },
    ],
  };

  return (
    <div className="p-8 bg-gray-100 min-h-screen relative">
      <div className="flex justify-between items-center mb-4">
      <img src="/logo-tp.png" alt="Brand Logo" className="w-24 h-auto" />
        <div className="text-end">
          <h1 className="text-3xl font-bold mb-4">Community Resource Assistant Analytics Report</h1>
          <h2 className="text-xl mb-2">Greenfield Union School District</h2>
          <p>May 1, 2024 - May 31, 2024</p>
        </div>
      </div>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-2">Executive Summary</h3>
        <p className="mb-4">
          The Community Resource Assistant chatbot on the Greenfield Union School District's homepage has shown strong performance and engagement during May 2024. With 5,200 interactions from 1,150 unique users, the chatbot effectively addressed the needs of the community. Users interacted with the chatbot an average of 4.5 times per session, spending an average of 3 minutes per session. Peak usage occurred between 7:00 PM and 9:00 PM. The chatbot successfully resolved 92% of queries, maintaining an average response time of 1.2 seconds. User satisfaction remains high, with an average rating of 4.5 out of 5 stars. Popular queries included school events, enrollment information, and lunch menus. Key resources, such as the school calendar and enrollment forms, were frequently accessed, demonstrating the chatbot's value in providing essential information. To further enhance the chatbot's effectiveness, it is recommended to improve the detail of event information, expand language support, and ensure all resources are up-to-date and easily accessible.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-2">User Interaction Metrics</h3>
        <div className="flex justify-center">
          <div className="w-1/2">
            <Bar data={interactionData} />
          </div>
        </div>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-2">User Demographics</h3>
        <div className="flex justify-center">
          <div className="w-1/2">
            <Pie data={languageData} />
          </div>
        </div>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-2">Query Analysis</h3>
        <div className="flex justify-center">
          <div className="w-1/2">
            <Bar data={queryData} />
          </div>
        </div>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-2">Performance Metrics</h3>
        <ul className="list-disc list-inside">
          <li>Response Time: Average of 1.2 seconds</li>
          <li>Resolution Rate: 92% of queries successfully resolved</li>
          <li>Escalation Rate: 8% of queries escalated to a human representative</li>
        </ul>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-2">User Feedback</h3>
        <ul className="list-disc list-inside">
          <li>User Satisfaction: 4.5/5 stars average rating</li>
          <li>Comments and Suggestions:
            <ul className="list-disc list-inside ml-4">
              <li>Positive feedback on quick response times</li>
              <li>Suggestions for more detailed event information</li>
            </ul>
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-2">Engagement and Retention</h3>
        <ul className="list-disc list-inside">
          <li>Returning Users: 60% of users returned for multiple sessions</li>
          <li>Engagement Rate: High engagement with 3 or more interactions in a session for 40% of users</li>
        </ul>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-2">Operational Insights</h3>
        <ul className="list-disc list-inside">
          <li>Peak Load Times: Highest volume on weekdays between 7:00 PM and 9:00 PM</li>
          <li>Usage by Platform:
            <ul className="list-disc list-inside ml-4">
              <li>Mobile: 70%</li>
              <li>Desktop: 25%</li>
              <li>Other devices: 5%</li>
            </ul>
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-2">Content and Resource Utilization</h3>
        <ul className="list-disc list-inside">
          <li>Frequently Accessed Resources:
            <ul className="list-disc list-inside ml-4">
              <li>School Calendar: 25%</li>
              <li>Enrollment Forms: 15%</li>
              <li>Lunch Menu: 10%</li>
              <li>Bus Schedule: 5%</li>
            </ul>
          </li>
          <li>Resource Effectiveness:
            <ul className="list-disc list-inside ml-4">
              <li>Positive feedback on ease of access to enrollment forms</li>
            </ul>
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-2">Privacy and Security</h3>
        <ul className="list-disc list-inside">
          <li>Data Compliance Metrics: All interactions are compliant with FERPA and COPPA regulations</li>
        </ul>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-2">Custom Metrics</h3>
        <ul className="list-disc list-inside">
          <li>Event-specific Queries:
            <ul className="list-disc list-inside ml-4">
              <li>End-of-year events: 12% of total queries</li>
            </ul>
          </li>
          <li>Program-specific Metrics:
            <ul className="list-disc list-inside ml-4">
              <li>Summer program inquiries: 18% increase compared to April</li>
            </ul>
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-2">Conclusion</h3>
        <p>
          The Community Resource Assistant on the Greenfield Union School District's homepage has demonstrated robust engagement and high user satisfaction throughout May 2024. Continuous monitoring and improvements based on user feedback and query analysis will further enhance the chatbot's effectiveness and support for the district's families.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-2">Recommendations</h3>
        <ul className="list-disc list-inside">
          <li>Enhance Event Information: Provide more detailed responses for specific event queries.</li>
          <li>Expand Language Support: Consider adding more language options based on user demographics.</li>
          <li>Improve Resource Accessibility: Ensure all key resources are easily accessible and up-to-date.</li>
        </ul>
      </section>
    </div>
  );
};

export default AnalyticsReport;
